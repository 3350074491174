<template>
  <v-carousel
      v-model="model"
      :height="calculateBannerHeight"
      cycle
      interval="15000"
      show-arrows-on-hover
      :light="lightTheme"
      @change="onSlideChanged"
      hide-delimiter-background>

    <v-carousel-item v-for="(banner, index) in banners" :key="index">
      <v-sheet :color="banner.color" height="100%">
        <v-img :contain="banner.image_contain" height="100%" :gradient="banner.gradient"  :src="require('../assets/' + banner.image)" >
          <v-container fill-height style="max-width: 1200px">
            <v-row align="center"
                   justify="center">
              <v-col>
                <v-sheet color="transparent" :style="!lightTheme ? 'color: #fff' : ''">
                  <div class="text-h2" v-html="banner.title"></div>
                  <div class="subtitle-1 pt-12" v-html="banner.desc" />
                  <div class="mt-3">
                    <v-btn :class=" button.theme === 'light' ? ' black--text' : 'theme--dark'" class="mr-3" v-for="(button, index) in banner.buttons" :key="index" :to="button.to"  :color="button.color">{{button.label}}</v-btn>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-sheet>
    </v-carousel-item>

  </v-carousel>
</template>

<script>
export default {

  methods: {

    onSlideChanged(index) {
      this.lightTheme = this.banners[index].theme === 'light'
    }
  },
  computed: {
    primaryColor() {

      return '#3b87c8'
      // return this.$vuetify.theme.themes.light.primary;
    },

    calculateBannerHeight() {
      if(this.$vuetify.breakpoint.xsOnly)
        return 800

      return 600
    }

  },
  data() {
    return {
      lightTheme: true,
      model: 0,
      //TODO: missing translations or restful request
      banners: [
        {
          title: "Witamy w firmie <b>Platforma Biznesu!</b>",
          image: 'logo_pb_large_transparent.png',
          desc: "Jesteśmy firmą produkcyjno-outsourcingową oferującą szeroką gamę <b>profesjonalnych usług i rozwiązań informatycznych</b>. Firma obsługuje Klientów zarówno z sektora prywatnego, jak i publicznego, a dzięki własnym centrom usług i centrom rozwoju produktów Platforma Biznesu jest w stanie świadczyć swoje usługi na poziomie globalnym i na wszystkich etapach ich rozwoju. Nasi specjaliści pracują nad najnowocześniejszymi rozwiązaniami dla międzynarodowych i lokalnych Klientów z różnych branż.",
          theme: 'light',
          buttons: [
            {label: "Nasze najnowsze produkty", to: "/products", color: 'primary'}
          ]
        },
        {
          title: "Zwiększ bezpieczeństwo niechronionych uczestników ruchu w Twoim regionie",
          image: 'taps/k_022.png',
          desc: "Celem systemu Urbanitus jest poprawa bezpieczeństwa i komfortu uczestników ruchu drogowego, należących do grupy tzw. niechronionych uczestników ruchu (pieszych, osób przemieszczających się rowerem, hulajnogą, deskorolką itp.).",
          theme: 'light',
          buttons: [
            {label: "Poznaj możliwości systemu", to: "/urbanitus", color: 'primary'}
          ]
        },
/*        {
          title: "Siegla MMS - Organizuj i dystrybuuj <b>multimedia!</b>",
          desc: "Wprowadziliśmy na rynek nowoczesny system – Digital Signage / System zarządzania treścią – umożliwiający łatwe i wygodne zarządzanie treścią, pozwala on na aktualizację i rozbudowę serwowanej treści. System opracowany przez naszych specjalistów jest elastyczny, umożliwia dopasowanie oprogramowania idealnie do potrzeb Klienta, który korzysta z naszych produktów w postaci kiosków multimedialnych.",
          image: 'img091.jpg',
          theme: 'dark',
          gradient: '90deg, rgba(0, 0, 0, 0.75) 50%, #3b87c8 100%',
          // gradient: '`90deg, rgba(0, 0, 0, 0.75) 50%, ${primaryColor} 100%`',
          buttons: [
            {label: "Przegląd funkcjonalności", to: "/taps/overview", color: 'primary'},
            {label: "Integracja z istniejącymi systemami", to: "/taps/overview", color: 'secondary', theme: 'light'}
          ]
        },*/

        {
          title: "Siegla QMS - <b>Usprawnij</b> obsługę swojego interesanta",
          desc: "System zarządzania kolejkami to produkt, który zainicjował nowy etap obsługi Klienta. Pozwala on na sprawne zarządzanie ruchem klientów, ograniczenie czasu oczekiwania interesantów oraz częściowe wyeliminowanie kolejek. System zapewnia komfort dla osób oczekujących, ma również bezpośredni wpływ na zadowolenie i opinię o danej placówce.",
          image: 'img091.jpg',
          theme: 'dark',
          gradient: '90deg, rgba(0, 0, 0, 0.75) 50%, #3b87c8 100%',
          // gradient: '`90deg, rgba(0, 0, 0, 0.75) 50%, ${primaryColor} 100%`',
          buttons: [
            {label: "Przegląd funkcjonalności", to: "/products/qms", color: 'primary'}
          ]
        },

        {
          title: "Rozwiązania dedykowane",
          image: 'img098_w.png',

          desc: "Platforma Biznesu posiada bogate, wieloletnie doświadczenie w zakresie projektowania i rozwoju systemów wbudowanych. Specjalizujemy się w projektowaniu, prototypowaniu, debugowaniu, testowaniu oraz produkcji oprogramowania i sprzętu. Nasza wiedza obejmuje obszary technologiczne, takie jak: sieci bezprzewodowe, automatyka budynkowa, projektowanie PCB, systemy wbudowane, sterowniki.",
          theme: 'light',
          buttons: [
            {label: "Nasze najnowsze produkty", to: "/products", color: 'primary'}
          ]
        },
        {
          title: "Witamy w firmie <b>Platforma Biznesu!</b>",
          image_contain: true,
          color: 'primary',
          image: 'logo_pb_large_transparent.png',
          desc: "Jesteśmy firmą produkcyjno-outsourcingową oferującą szeroką gamę <b>profesjonalnych usług i rozwiązań informatycznych</b>. Firma obsługuje Klientów zarówno z sektora prywatnego, jak i publicznego, a dzięki własnym centrom usług i centrom rozwoju produktów Platforma Biznesu jest w stanie świadczyć swoje usługi na poziomie globalnym i na wszystkich etapach ich rozwoju. Nasi specjaliści pracują nad najnowocześniejszymi rozwiązaniami dla międzynarodowych i lokalnych Klientów z różnych branż.",
          theme: 'dark',
          buttons: [
            {label: "Nasze najnowsze produkty", to: "/products", color: '#111', theme: 'dark'}
          ]
        },
      ]
    }
  },
}


</script>
