<template>
  <v-sheet>
    <MainBanner class="mb-4" />
    <v-divider />
    <ProductBox />

  </v-sheet>
</template>

<script>

import MainBanner from "@/components/MainBanner";
import ProductBox from "@/components/ProductBox";
export default {
  name: 'Home',
  components: {ProductBox, MainBanner}
}
</script>
